import { UserBlocked } from '@app/store/models';
import { CustomerDto } from './index';

export class Customer {
    no: string;
    name: string;
    balance: string;
    balanceDue: number;
    creditLimitLcy: string;
    salesPersonCode: string;
    locationCode: string;
    blocked: UserBlocked | null;
    restaurantName: string;
    vatRegistrationNo: string;
    postCode: string;
    city: string;
    searchName: string;
    addresses: string;
    paymentTerm: string;
    paymentMethod: string;

    constructor(customerDto: CustomerDto) {
        this.no = customerDto.no;
        this.name = `${customerDto.name ?? ''}${customerDto.name2 ?? ''}`;
        this.balance = customerDto.balance;
        this.balanceDue = isNaN(+customerDto.balance_due) ? 0 : Math.round(100 * +customerDto.balance_due);
        this.creditLimitLcy = customerDto.credit_limit_lcy;
        this.salesPersonCode = customerDto.sales_person_code;
        this.locationCode = customerDto.location_code;
        this.blocked = customerDto.blocked === '' || customerDto.blocked === ' ' ? null : customerDto.blocked === 'Vindication' ? 'Ship' : customerDto.blocked;
        this.restaurantName = customerDto.restaurant_name;
        this.vatRegistrationNo = customerDto.vat_registration_no;
        this.postCode = customerDto.post_code;
        this.city = customerDto.city;
        this.searchName = customerDto.search_name;
        this.addresses = customerDto.addresses;
        this.paymentTerm = customerDto.payment_terms;
        this.paymentMethod = customerDto.payment_method;
    }
}
